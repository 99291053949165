import { onDesktop } from "@naf/teamscheme";
import React, { type HTMLAttributes } from "react";
import styled from "styled-components";
import {
  Point,
  PointState,
  ProgressNumberList,
} from "../shared/elements/ProgressCheckList";

const HowToHeader = styled.p`
  ${({ theme }) => theme.mobileFontStyle.headers.header1}
  text-align: left;
  margin: 0;
  max-width: 440px;

  strong {
    color: ${({ theme }) => theme.nafColor.primary.park};
    font-weight: 900;
    font-size: 1.2em;
  }

  ${({ theme }) => onDesktop`
    ${theme.fontStyle.headers.header1}
  `}
`;

const Header = styled(function (
  props: Omit<HTMLAttributes<HTMLDivElement>, "children">,
) {
  return (
    <div {...props}>
      <HowToHeader>
        Over <strong>500&nbsp;000</strong> fornøyde brukere har signert
        digitalt!
      </HowToHeader>
    </div>
  );
})`
  flex-basis: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const List = styled(ProgressNumberList)`
  margin-bottom: 0;

  ${Point} {
    padding-bottom: ${({ theme }) => theme.spacing.space20};
  }
  ${Point}:last-child {
    padding-bottom: 0;
  }
`;

const Timeline = styled(function (
  props: Omit<HTMLAttributes<HTMLDivElement>, "children">,
) {
  return (
    <div {...props}>
      <h2>Slik fungerer det:</h2>
      <List
        points={[
          {
            title: "Fyll ut din del",
            status: PointState.Inactive,
            content: (
              <span>
                Kjøpekontrakten er delt inn i kjøper og selgers del. Alle
                påkrevde felter skal fylles ut om deg, kjøretøy og hvordan dere
                har avtalt å gjøre opp.
              </span>
            ),
          },
          {
            title: "Inviter din motpart",
            status: PointState.Inactive,
            content: (
              <span>
                Du kan sende invitasjon for utfylling av kjøpekontrakt på SMS
                eller e-post.
              </span>
            ),
          },
          {
            title: "Signer digitalt",
            status: PointState.Inactive,
            content: (
              <span>
                Dokumentene signeres digitalt BankID eller InkSign
                fingersignatur.
              </span>
            ),
          },
          {
            title: "Fullfør handelen",
            status: PointState.Inactive,
            content: (
              <span>
                Gjennomføre oppgjør og overlevering av bilen. Overføring av
                eierskap hos Statens vegvesen og gebyrfri betaling med Vipps
                gjøres i dette steget hvis det er valgt.
              </span>
            ),
          },
        ]}
      />
    </div>
  );
})`
  flex-basis: 50%;
`;

export const HowToSection = styled(function (
  props: Omit<HTMLAttributes<HTMLDivElement>, "children">,
) {
  return (
    <div {...props}>
      <Header />
      <Timeline />
    </div>
  );
})`
  display: flex;
  gap: ${({ theme }) => theme.spacing.space32};
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  ${onDesktop`
    flex-direction: row;
  `}
`;
