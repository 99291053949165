import { Button } from "@naf/teamscheme";
import { onDesktop } from "@naf/teamscheme";
import { type HTMLAttributes, useState } from "react";
import styled from "styled-components";
import { EVENTS } from "../Tracking";
import { ContractPreviewWrapper } from "../contract/preview/ContractPreviewWrapper";
import { DocumentOverlay } from "../contract/preview/DocumentOverlay";
import ZoomableScrollImage from "../contract/preview/ZoomableImage";

import Default from "./dummy-contracts/kjopekontrakt-for-bruktbil-240109.png";
import Caravan from "./dummy-contracts/kjopekontrakt-for-camping-240109.png";
import Mc from "./dummy-contracts/kjopekontrakt-for-mc-240109.png";

export enum DummyContractType {
  Default = 0,
  Caravan = 1,
  Mc = 2,
}

export const DummyContractPath = {
  [DummyContractType.Default]: Default,
  [DummyContractType.Caravan]: Caravan,
  [DummyContractType.Mc]: Mc,
};

export interface DummySectionProps
  extends Omit<HTMLAttributes<HTMLDivElement>, "title"> {
  onStartNew(): void;
  contractType?: DummyContractType;
  title?: string;
}

const DummyBody = styled.div`
  margin-bottom: 2em;
  p {
    line-height: 1.75;
  }
`;

export const DummySection = styled(function ({
  onStartNew,
  title,
  children,
  contractType,
  ...props
}: DummySectionProps) {
  const [isZoomed, setIsZoomed] = useState(false);

  return (
    <div {...props}>
      <DummyBody>
        <h2>{title || "Hva inneholder en kjøpekontrakt?"}</h2>
        {children || (
          <>
            <p>
              Våre advokater har utarbeidet NAF Kjøpekontrakt slik at den
              inneholder nødvendige opplysninger om kjøretøyet, dens tilstand,
              utstyr som følger med, informasjon om oppgjøret og partenes
              personopplysninger. Kjøper og selger bør også skrive ned alt de
              har blitt enige om muntlig og skriftlig form.
            </p>
            <p>
              Kjøpekontrakten tilpasser seg også automatisk til en mengde ulike
              kjøretøy basert på registreringsnummeret. Det betyr at kjøper og
              selger kun trenger å forholde seg til felter som er relevante for
              det aktuelle kjøretøyet enten det er en personbil, MC, minibuss,
              campingvogn eller bobil.
            </p>
            <p>
              Andre kjøretøy som støttes er: traktor, snøscooter, lastebil,
              henger, moped, og ATV.
            </p>
          </>
        )}
        <Button linkTo="/" variant="outline" onClick={onStartNew}>
          Kom i gang med utfyllingen
        </Button>
      </DummyBody>
      <ContractPreviewWrapper onClick={() => setIsZoomed((prev) => !prev)}>
        <ImageContainer>
          <ZoomableScrollImage
            isZoomed={isZoomed}
            setIsZoomed={setIsZoomed}
            className="DocumentPreview"
            alt="Forhåndsvisning av eksempelkontrakt"
            src={DummyContractPath[contractType || DummyContractType.Default]}
            onOpenEvent={EVENTS.LANDING_PAGE.OPEN_PREVIEW}
          />
          <DocumentOverlay />
        </ImageContainer>
      </ContractPreviewWrapper>
    </div>
  );
})`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${({ theme }) => onDesktop`
    flex-direction: row;

    ${DummyBody}{
      padding-right: ${theme.spacing.space120}
    }
  `}
`;
const ImageContainer = styled.div``;
