import {
  type ButtonLinkProps,
  type ButtonLinkVariant,
  type DateString,
  type TrackEventProps,
  ButtonLink,
  TextLink,
} from "@naf/teamscheme";
import { useCallback, useEffect, useMemo, useState } from "react";
import type { To } from "react-router-dom";
import styled from "styled-components";
import type { ContractSource } from "#vehicle-contract/common/lib/model/contract/ContractSource";
import { Role } from "#vehicle-contract/common/lib/model/contract/Role";
import type { SignatureStatus } from "#vehicle-contract/common/lib/model/contract/SalesContract";
import { EVENTS, useTracking } from "../Tracking";
import type { PaginationResult } from "../admin/api/PaginationResult";
import { api } from "../api/ApiClient";
import { type ActionClient, useClientBase } from "../api/ClientBase";
import { useAuth } from "../auth/AuthProvider";
import { getContractPaths } from "../contract/routing/ContractUrls";
import { formatDate } from "../formatting/date";
import type { ContractToken } from "../contract/model/ContractViewModel";

interface PublicSalesContract {
  contractId: string;
  licensePlateNumber: string;
  displayName: string;
  role: Role;
  source: ContractSource;
  customerId: string;
  customerGuid: string;
  created: DateString;
  signedDate?: DateString;
  completedDate?: DateString;
  archivedDate?: DateString;
  signatureStatus: SignatureStatus;
  confirmationPageUrl: string;
  landingPageUrl: string;
  token: ContractToken;
}

export interface PublicContracts
  extends ActionClient<void, PaginationResult<PublicSalesContract>> {
  loadNextPage?(): void;
}

interface LoadNextPageRequest {
  cursor: string;
}

export function usePublicContracts(): PublicContracts {
  const [items, setItems] = useState<PublicSalesContract[]>([]);
  const contracts = useClientBase<
    // biome-ignore lint/suspicious/noConfusingVoidType: <explanation>
    void | LoadNextPageRequest,
    PaginationResult<PublicSalesContract>
  >(
    useCallback((request) => {
      const search = new URLSearchParams();
      if (typeof request === "object") {
        search.set("cursor", request.cursor);
      }
      return api.get(`contract?${search.toString()}`);
    }, []),
  );

  useEffect(() => {
    if (!contracts.result) return;
    const { items: nextItems } = contracts.result;
    setItems((prev) => [...prev, ...nextItems]);
  }, [contracts.result]);

  const { result, fetch: fetchContracts } = contracts;
  const cursor = result?.cursor;
  const { isAuthenticated } = useAuth();

  useEffect(() => {
    if (isAuthenticated) fetchContracts();
  }, [fetchContracts, isAuthenticated]);

  const loadNextPage = useMemo(() => {
    if (!cursor) return undefined;
    const memoizedCursor = cursor;
    return () => fetchContracts({ cursor: memoizedCursor });
  }, [cursor, fetchContracts]);

  const combinedResult = useMemo(
    () =>
      contracts.result
        ? {
            ...contracts.result,
            items,
          }
        : null,
    [contracts.result, items],
  );

  return useMemo<PublicContracts>(
    () => ({
      ...contracts,
      result: combinedResult,
      loadNextPage,
    }),
    [contracts, combinedResult, loadNextPage],
  );
}

const ContractLinkWrapper = styled.div`
  width: 100%;
  padding: ${({ theme }) => theme.spacing.space12} 0;
`;

const TextLinkWrapper = styled.div`
  display: block;
`;

const ContractDetails = styled.span`
  ${({ theme }) => theme.fontStyle.bodyText.small};
`;

function ContractLink({
  to,
  role,
  title,
  created,
  trackEvent,
  signedDate,
  completedDate,
  archivedDate,
}: {
  to: To;
  role: Role;
  title: string;
  created: string;
  trackEvent?: TrackEventProps;
  signedDate?: DateString;
  completedDate?: DateString;
  archivedDate?: DateString;
}) {
  const tracking = useTracking();
  function onClick() {
    const base: TrackEventProps =
      trackEvent || EVENTS.LANDING_PAGE.OPEN_SAVED_CONTRACT;
    tracking.event({
      ...base,
      label: role === Role.Seller ? "Seller" : "Buyer",
    });
  }

  const props = { onClick, linkTo: to };
  const verb = role === Role.Buyer ? "Kjøp" : "Salg";

  return (
    <ContractLinkWrapper>
      <TextLinkWrapper>
        <TextLink {...props}>
          {verb} av {title}
        </TextLink>
      </TextLinkWrapper>
      <ContractDetails>
        {archivedDate ? (
          <span>{formatDate(archivedDate)} &ndash; Arkivert</span>
        ) : completedDate ? (
          <span>{formatDate(completedDate)} &ndash; Fullført</span>
        ) : signedDate ? (
          <span>{formatDate(signedDate)} &ndash; Venter på fullføring</span>
        ) : (
          <span>{formatDate(created)} &ndash; Opprettet</span>
        )}
      </ContractDetails>
    </ContractLinkWrapper>
  );
}
const ListItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.nafColor.neutral.neutral2};
`;

const Section = styled.section`
  margin-bottom: ${({ theme }) => theme.spacing.space16};
`;

export default function SavedContracts({
  showStartNew,
  onStartNew,
  publicContracts,
}: {
  showStartNew?: boolean;
  onStartNew?: () => void;
  publicContracts: PublicContracts;
}) {
  const variant: ButtonLinkVariant = "primary";
  const startNewBaseProps = {
    variant,
    trackEvent: EVENTS.LANDING_PAGE.START_NEW,
    children: "Start en ny kontrakt",
  };
  const startNewButtonProps: ButtonLinkProps = onStartNew
    ? { ...startNewBaseProps, onClick: onStartNew }
    : { ...startNewBaseProps, linkTo: "/opprett" };
  return (
    <div>
      {publicContracts.result?.items.length ? (
        <Section>
          <h4>Dine kontrakter fra Mitt NAF:</h4>
          {publicContracts.result.items.map(
            ({
              contractId,
              displayName,
              created,
              role,
              token,
              signedDate,
              completedDate,
              archivedDate,
            }) => (
              <ListItem key={`${contractId}/${role}/${created}`}>
                <ContractLink
                  to={getContractPaths(token).contract}
                  role={role}
                  title={displayName}
                  created={created}
                  signedDate={signedDate}
                  completedDate={completedDate}
                  archivedDate={archivedDate}
                />
              </ListItem>
            ),
          )}
          {publicContracts.loadNextPage ? (
            <FetchMoreLink
              onClick={publicContracts.loadNextPage}
              isLoading={publicContracts.isLoading}
            >
              Hent flere
            </FetchMoreLink>
          ) : null}
        </Section>
      ) : null}
      {showStartNew ? <ButtonLink {...startNewButtonProps} /> : null}
    </div>
  );
}

const FetchMoreLink = styled(TextLink)`
  margin: ${({ theme }) => theme.spacing.space16} 0 0;
`;
