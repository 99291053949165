import { Button } from "@naf/teamscheme";
import { type CSSProperties, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { TopFaq } from "../about/faq/TopFaq";
import { AppPages } from "../routing/AppPages";
import { getAbsolutePath } from "../routing/AppRoutes";

const FaqWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  margin-bottom: ${({ theme }) => theme.spacing.space32};
`;

export function FaqSection() {
  const container = useRef<HTMLDivElement | null>(null);
  const [containerStyle, setContainerStyle] = useState<CSSProperties>();
  useEffect(() => {
    if (container.current)
      setContainerStyle({
        width: `${container.current.getBoundingClientRect().width}px`,
      });
  }, []);
  return (
    <FaqWrapper>
      <div ref={container} style={containerStyle}>
        <h2>Lurer du på noe?</h2>
        <p>
          Finn svar på det du lurer på &ndash; eller ta kontakt med oss på
          chatten. Vi hjelper så gjerne!
        </p>
        <TopFaq />
        <Button linkTo={getAbsolutePath(AppPages.AboutFaq)} variant="outline">
          Se vanlige spørsmål
        </Button>
      </div>
    </FaqWrapper>
  );
}
