import type { ImgHTMLAttributes } from "react";
import styled from "styled-components";
import Logo from "./graphics/bankid-signering_274.5x28.svg";

const SignedWithBankId = styled(function (
  props: Omit<ImgHTMLAttributes<HTMLImageElement>, "src" | "alt">,
) {
  return <img {...props} src={Logo} alt="BankID signering" />;
})``;

export default SignedWithBankId;
